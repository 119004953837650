<template>
  <div class="q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['mishapScarsSize'])">
      <single-answer-question
        :title="title"
        :value="mishapScarsSize"
        :options="$options.mishapScarSizeOptions"
        dysfunction="skin-appearance"
        @input="onFieldChange('mishapScarsSize', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';
import { MISHAP_SCAR_SIZE } from '@/modules/questionnaire/api/constants';

const MISHAP_SCAR_SIZE_OPTIONS = [
  { value: MISHAP_SCAR_SIZE.UNDER_10_CM, text: 'otherScarsCoverage.under10Cm' },
  { value: MISHAP_SCAR_SIZE.LARGE_COVERAGE, text: 'otherScarsCoverage.largeCoverage' }
];

export default {
  name: 'OtherScarsTemplate',
  components: { QuestionWithErrorWrap, SingleAnswerQuestion },
  mixins: [stepTemplateMixin],
  mishapScarSizeOptions: MISHAP_SCAR_SIZE_OPTIONS,
  props: {
    mishapScarsSize: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  }
};
</script>
